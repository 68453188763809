
import { defineComponent, computed } from 'vue'
import moment from 'moment'

import { RankingItem } from '../types'

interface Props {
  item: RankingItem
}

export default defineComponent({
  props: {
    item: {
      type: Object as () => RankingItem,
      required: true,
    },
  },
  setup(props: Props) {
    const id = computed(() => props.item?.id)
    const title = computed(() => props.item?.title)
    const link = computed(() => `/archives/${id.value}`)
    const thumbnailUrl = computed(() => props.item?.thumbnail_url)
    const createdAt = computed(() => {
      if (!props.item?.date) return

      return moment(props.item.date).format('YYYY/MM/DD')
    })

    const imgWidth = 500
    const imgHeight = 281

    return {
      id,
      title,
      link,
      thumbnailUrl,
      createdAt,
      imgWidth,
      imgHeight,
    }
  },
})
