
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'

import RankingItem from './RankingItem.vue'

export default defineComponent({
  components: {
    RankingItem,
  },
  setup() {
    const items = ref([])

    const getRanking = async () => {
      const res = await axios.get('/ranking.json')
      if (res?.data) {
        items.value = res.data.slice(0, 5)
      }
    }

    onMounted(() => {
      getRanking()
    })

    return {
      items,
    }
  },
})
